
// Libraries
import * as React from 'react'
import _ from "lodash"

// Services
import ApiService from "../../../services/api"
import BallotService from "../../../services/ballot"

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminUsersTableHeader from '../../../components/admin/users/adminUsersTableHeader'
import Pagination from '../../../components/pagination'
import AdminUserBallotsTable from '../../../components/admin/users/adminUserBallotsTable'
import AdminBallotConfirmDelete from '../../../components/admin/users/adminBallotConfirmDelete'
import Button from '../../../components/button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTicketAlt, faTrashAlt} from '@fortawesome/pro-solid-svg-icons'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminUsersBallotsPage extends React.Component {
	state = {
		activeFilters: {
			filter: {}
		},
		user: {},
		ballots: [],
		pagination: [],
		emptyMessage: "Retrieving ballot data ...",
		selectedBallots: [],
		selectedBallotsCount: 0,
		modalConfirmDeleteShow: false,
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, user, ballots, pagination, emptyMessage,
			selectedBallots, selectedBallotsCount, modalConfirmDeleteShow} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table nav-blue-half" hideSideNav>
				<Seo title="Events Management" />
				<div className="admin-table__wrap admin-table__wrap--users-ballots admin-table__wrap--buttons-bottom">
					<AdminUsersTableHeader
						user={user}
						subTitleText="Ballots for"
						titleText={`${user.first_name} ${user.last_name}`}
						titleIcon={faTicketAlt}
						searchValue={activeFilters.search}
						searchHandler={_.debounce(this.handleSearchChange, 500)}
						csvButton />

					<Pagination pagination={pagination} handlePaginationClick={this.handlePaginationClick} />

					<AdminUserBallotsTable
						selectedBallots={selectedBallots}
						ballots={ballots}
						emptyMessage={emptyMessage}
						handleBallotToggle={this.handleBallotToggle}
						handleTicketQtyUpdate={this.handleTicketQtyUpdate} />

					<div className="buttons-bottom">
						<div className="col-left">
							<p className="select-count">{selectedBallotsCount} ballots selected</p>
							<Button onClick={() => this.handleConfirmDeleteShow()} iconOnly danger>
								<FontAwesomeIcon icon={faTrashAlt} title="Remove icon" />
								<span className="sr-only">Remove Ballot</span>
							</Button>
						</div>
						<div className="col-right">
							<Button to="/admin/users" hollow colorEndeavour>Cancel</Button>
						</div>
					</div>

					{modalConfirmDeleteShow && <AdminBallotConfirmDelete
						selectedBallotsCount={selectedBallotsCount}
						handleClose={() => this.setState({modalConfirmDeleteShow: false})}
						handleConfirm={this.handleConfirmDelete} />}
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		const {userId} = this.props
		this.getUser(userId)

		Promise.all([
			this.getBallots(userId, activeFilters)
				.catch(() => addNotification('There was an error fetching the ballots.', 'error'))
		]).then(() => {
			this.setState({
				emptyMessage: "No ballots found, try adjusting your filters"
			})
		})
	}

	getUser = (userId) => {
		const apiService = new ApiService()
		return apiService.get(`users/${userId}`)
			.then( (response) => {
				this.setState({user: response.data})
			}).catch(err => console.error(err))
	}

	getBallots = (userId, queryParams) => {
		const apiService = new ApiService()
		queryParams['filter']['user_id'] = userId
		return apiService.get('ballots', {}, queryParams)
			.then( (response) => {
				this.setState({
					ballots: response.data.data,
					pagination: response.data,
					activeFilters: queryParams
				})
			}).catch(err => console.error(err))
	}

	handleSearchChange = (searchValue) => {
		const {user, activeFilters} = this.state
		const {addNotification} = this.context

		if (searchValue) {
			activeFilters['search'] = searchValue
		} else if (activeFilters['search']) {
			delete activeFilters['search']
		}

		if (activeFilters['page']) {
			delete activeFilters['page']
		}

		this.setState({activeFilters}, () => {
			this.getBallots(user.id, activeFilters)
				.catch(() => addNotification('There was an error filtering the ballots', 'error'))
		})
	}

	handlePaginationClick = (page) => {
		const {user, activeFilters} = this.state
		const {addNotification} = this.context

		activeFilters['page'] = page

		this.setState({activeFilters}, () => {
			this.getBallots(user.id, activeFilters)
				.catch(() => addNotification('There was an error filtering the ballots.', 'error'))
		})
	}

	handleBallotToggle = (isChecked, ballot) => {
		const {selectedBallots} = this.state

		if (isChecked) {
			// add to selectedBallots
			selectedBallots.push(ballot)
		}
		else {
			// remove from selectedBallots
			selectedBallots.forEach((selectedBallot, key) => {
				if (selectedBallot.id === ballot.id) {
					selectedBallots.splice(key, 1)
				}
			})
		}

		this.setState({
			selectedBallots: selectedBallots,
			selectedBallotsCount: selectedBallots.length
		})
	}

	handleTicketQtyUpdate = async (ballotId, qty) => {
		const {addNotification} = this.context

		const ballotService = new BallotService()
		const ballotResponse = await ballotService.update(ballotId, {tickets_requested: qty})
		if (ballotResponse.success) {
			this.updateStateBallots(ballotResponse.data)
		}
		else {
			addNotification('Unable to update tickets', 'error')
		}
	}

	handleConfirmDeleteShow = () => {
		const {selectedBallotsCount} = this.state
		const {addNotification} = this.context

		if (selectedBallotsCount > 0) {
			this.setState({
				modalConfirmDeleteShow: true
			})
		}
		else {
			addNotification('No ballots selected', 'error')
		}
	}

	handleConfirmDelete = async () => {
		const {selectedBallots, selectedBallotsCount} = this.state
		const {addNotification} = this.context

		if (selectedBallotsCount > 0) {
			// prepare data
			const ballotData = JSON.stringify({
				ballots: selectedBallots.map((selectedBallot) => selectedBallot.id.toString()),
			})

			// submit data
			const apiService = new ApiService()
			await apiService.put('ballots/remove', {body: ballotData})
				.then(async (ballotResponse) => {
					if (ballotResponse.success) {
						// remove ballots from view
						ballotResponse.data.ballots.forEach((removedBallot) => {
							this.updateStateBallots(removedBallot)
						})

						// hide modal & reset selected ballots
						this.setState({
							modalConfirmDeleteShow: false,
							selectedBallots: [],
							selectedBallotsCount: 0
						})

						// success notification
						addNotification('Ballots removed', 'success')
					}
					else {
						addNotification('Unable to remove ballots', 'error')
					}
				})
				.catch(err => console.error(err))
		}
		else {
			addNotification('No ballots selected', 'error')
		}
	}

	updateStateBallots = (ballot, remove = false) => {
		const {ballots, selectedBallots} = this.state

		// update ballots state
		ballots.forEach((existingBallot, key) => {
			if (existingBallot.id === ballot.id) {
				if (remove) {
					ballots.splice(key, 1)
				}
				else {
					ballots[key] = {...existingBallot, ...ballot}
				}
			}
		})

		// update selectedBallots state
		selectedBallots.forEach((existingBallot, key) => {
			if (existingBallot.id === ballot.id) {
				if (remove) {
					selectedBallots.splice(key, 1)
				}
				else {
					selectedBallots[key] = {...existingBallot, ...ballot}
				}
			}
		})

		this.setState({ballots, selectedBallots})
	}
}

export default AdminUsersBallotsPage
