
// Libraries
import * as React from 'react'
import Classnames from "classnames"

// Components
import AdminUserBallotsTableRow from "./adminUserBallotsTableRow"

class AdminUserBallotsTable extends React.Component {
	render() {
		const {ballots, selectedBallots, emptyMessage,
			handleBallotToggle, handleTicketQtyUpdate} = this.props

		const classNames = Classnames([
			'admin-table admin-table--users admin-table--users-ballots wrap--scrollx',
		])

		const selectedBallotIds = selectedBallots.map((selectedBallot) => selectedBallot.id)

		return (
			<div className={classNames}>
				<table className="admin-table__table">
					<thead>
						<tr>
							<th><span className="sr-only">Assign</span></th>
							<th>Entry Id</th>
							<th>Event Name</th>
							<th>Event Date</th>
							<th>Event Venue</th>
							<th>Applied</th>
							<th>Tickets</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
					{
						selectedBallots.map((ballot) => <AdminUserBallotsTableRow
							key={ballot.id}
							ballot={ballot}
							handleBallotToggle={handleBallotToggle}
							handleTicketQtyUpdate={handleTicketQtyUpdate}
							isSelected />)
					}
					{
						ballots?.length
							? ballots.map((ballot) => !selectedBallotIds.includes(ballot.id) && <AdminUserBallotsTableRow
								key={ballot.id}
								ballot={ballot}
								handleBallotToggle={handleBallotToggle}
								handleTicketQtyUpdate={handleTicketQtyUpdate} />)
							: <tr><td colSpan={100} align="center">{emptyMessage}</td></tr>
					}
					</tbody>
				</table>
			</div>
		)
	}
}

export default AdminUserBallotsTable
